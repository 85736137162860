import Layout from '@components/ACOLayout/ACOLayout';
import Home from '@components/layout/Home/Home';
import {ObjectType} from '@service/model';
import { useRouter } from 'next/router';
import React from 'react';

const Index = () => {
  const router = useRouter()

  return <Layout> <Home filterHome={router.query.home as ObjectType} /> </Layout>;
};

export default Index;
