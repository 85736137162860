import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeResponsiveStyles } from '@providers/Responsive.provider';

export default makeResponsiveStyles(responsive => theme =>
  createStyles({
    avatarBox: {
      [responsive.onDesktop]: {
        marginRight: '30px'
      }
    },
    avatar: {
      cursor: 'pointer',
      '&:hover': {
        opacity: '70%',
      },
    },
    buttonGroup: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      borderRadius: '8px',
      padding: '7px',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '&.smallView': {
        padding: 0,
      },
      [responsive.onMobile]: {
        margin: '0 10px'
      }
    },
    buttonRoot: {
      width: '100%',
      padding: '8px',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.primary.main,
      },
    },
    buttonLabel: {
      [theme.breakpoints.up('lg')]: {
        padding: '0px 15px',
      },
      [theme.breakpoints.only('md')]: {
        padding: '0px 4px',
      },
      'flexDirection': 'column',
      'textTransform': 'none',
      'color': theme.palette.text.primary,
      'fontSize': '14px',
      'fontWeight': 400,
      '&.smallView': {
        padding: '10px',
      },
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    divider: {
      height: '60px',
      margin: 'auto 8px',
      '&.smallView': {
        margin: 'auto 0',
        maxHeight: '49px',
      },
    },
    dividerComponent: {
      margin: theme.spacing(2, 0, 0),
    }
  })
);
