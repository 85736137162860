import { Advertising } from '@components/Advertising/Advertising';
import { IHomeProperties } from '@components/layout/Home/Home';
import Assignment from '@mui/icons-material/AssignmentOutlined';
import Book from '@mui/icons-material/BookOutlined';
import Description from '@mui/icons-material/DescriptionOutlined';
import FormatListBulleted from '@mui/icons-material/FormatListBulletedOutlined';
import Forum from '@mui/icons-material/ForumOutlined';
import { Box, List, ListItem, Typography } from '@mui/material';
import { ObjectType } from '@service/model';
import { useRouter } from 'next/router';
import React from 'react';

import styles from './MenuFilterCards.styles';

interface MenuFilterCardsProperties {
  onSelect?: (key: ObjectType) => void;
}

const MenuFilterCards: React.FC<{ propertiesMenuFilter: MenuFilterCardsProperties, properties?: IHomeProperties }> = ({
                                                                                                                        propertiesMenuFilter,
                                                                                                                        properties,
                                                                                                                      }) => {

  const router = useRouter();
  // @ts-ignore
  const classes = styles();

  const buttons = [
    {
      key: null,
      text: 'Todo',
      icon: FormatListBulleted,
      class: classes.secondary,
    },
    {
      key: ObjectType.TICKET,
      text: 'Consultas',
      icon: Forum,
      class: classes.ticket,
    },
    {
      key: ObjectType.POST,
      text: 'Publicaciones',
      icon: Assignment,
      class: classes.post,
    },
    {
      key: ObjectType.DOCUMENT,
      text: 'Documentos',
      icon: Description,
      class: classes.document,
    },
    {
      key: ObjectType.NEWSLETTER,
      text: 'Boletines',
      icon: Book,
      class: classes.newsletter,
    },
    // {
    //   key: ObjectType.DOCUMENT_SPONSORED,
    //   text: 'Sponsoreados',
    //   icon: AssignmentLate,
    //   class: classes.sponsored
    // }
  ];

  const [selectedKey, setSelectedKey] = React.useState(properties.filterHome ? properties.filterHome : buttons[0].key);

  const handleListItemClick = (key: ObjectType) => {
    setSelectedKey(key);
    router.push({ pathname: '/', query: key ? { home: key } : null });

    if (propertiesMenuFilter.onSelect) {
      propertiesMenuFilter.onSelect(key);
    }
  };


  return (
    <Box className={classes.mainBox}>
      <Advertising />
      <Box>
        <Typography variant='body1' className={classes.text}>
          Ver en inicio
        </Typography>
      </Box>

      <Box width='100%' paddingRight='3px'>
        <List className={classes.buttonList}>
          {buttons.map((button) => {
            const Icon = button.icon;

            return (
              <ListItem
                key={button.key}
                button
                selected={properties.filterHome ? properties.filterHome === button.key : selectedKey === button.key}
                onClick={() => handleListItemClick(button.key)}
                classes={{ root: `${classes.button} ${button.class}`, selected: classes.selected }}>
                <Icon className={classes.icon} />
                <Typography variant='body1'>{button.text}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default MenuFilterCards;
