import ACOLink from '@components/ACOLink/ACOLink';
import { Avatar } from '@components/Avatar/Avatar';
import { CreatePost } from '@components/modal/CreatePost/CreatePost';
import { LoginModal } from '@components/modal/Login/LoginModal';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Box, Button, Divider, SvgIconTypeMap, useScrollTrigger, Zoom } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import NewPostProvider from '@providers/post/NewPostContext';
import { OnDesktop } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { PostSVG } from '@svg/index';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import styles from './ActionBar.styles';

interface IButton {
  icon: OverridableComponent<SvgIconTypeMap>;
  onclick: () => void;
  label: string;
  actionsToRedirect: () => void;
}

interface IActionBar {
  smallView?: boolean;
}

const ActionBar: React.FC<IActionBar> = ({ smallView }) => {
  const classes = styles();

  const { user, isLogged } = useSession();
  const router = useRouter();
  const scroll = useScrollTrigger({ disableHysteresis: true, threshold: 100 });
  const userVerified = user && user.verified;

  const [postOpen, setPostOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [notRedirect, setNotRedirect] = useState<boolean>(false);

  const newTicket = () => {
    router.push('/tickets/new');
  };

  const myTickets = () => {
    router.push(`/user/${user?.id}?optionProfile=Ticket`);
  };

  const newPost = () => {
    setPostOpen(true);
  };

  const openLoginModal = () => {
    setLoginOpen(true);
  };

  const redirectTicket = () => {
    router.push(
      {
        query: { destination: userVerified ? `user/${user?.id}?optionProfile=Ticket` : 'tickets/new' },
      },
      undefined,
      { shallow: true }
    );

    openLoginModal();
  };

  const redirectPost = () => {
    setNotRedirect(true);

    openLoginModal();
  };

  const buttons: IButton[] = [
    {
      onclick: userVerified ? myTickets : newTicket,
      icon: ForumOutlinedIcon,
      label: userVerified ? 'Mis consultas' : 'Crear consulta',
      actionsToRedirect: userVerified ? redirectTicket : newTicket,
    },
    {
      onclick: newPost,
      icon: PostSVG,
      label: 'Crear publicación',
      actionsToRedirect: redirectPost,
    },
  ];

  return smallView ? (
    <Zoom in={scroll} timeout={200}>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-evenly' alignItems='center'>
          <Box display='flex' className={clsx(classes.buttonGroup, { smallView })}>
            {buttons
              .map((button: IButton, index: number) => {
                const Icon = button.icon;

                return (
                  <Button
                    key={index}
                    variant='text'
                    color='primary'
                    size='small'
                    classes={{ root: classes.buttonRoot, text: clsx(classes.buttonLabel, { smallView }) }}
                    fullWidth
                    onClick={() => (isLogged ? button.onclick() : button.actionsToRedirect())}>
                    <Icon color='primary' fontSize='medium' width='24px' />
                    <Box m='2px' />
                    {button.label}
                  </Button>
                );
              })
              .reduce((prev, curr) => (
                <>
                  {prev}
                  <Divider className={clsx(classes.divider, { smallView })} orientation='vertical' variant='middle' />
                  {curr}
                </>
              ))}
          </Box>
        </Box>
        <NewPostProvider>
          <CreatePost props={{ open: postOpen, onClose: () => setPostOpen(false) }} />
        </NewPostProvider>
        {loginOpen && (
          <LoginModal
            modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
            loginModalProps={{ viewLogin: true }}
          />
        )}
      </Box>
    </Zoom>
  ) : (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-evenly' alignItems='center'>
        <OnDesktop>
          {isLogged && (
            <ACOLink href={`/user/${user?.id}`}>
              <Box className={classes.avatarBox}>
                <Avatar
                  src={user?.avatarUrl}
                  name={user?.fullName}
                  size={{ desktop: 48, mobile: 48 }}
                  classes={classes.avatar}
                />
              </Box>
            </ACOLink>
          )}
        </OnDesktop>
        <Box display='flex' className={classes.buttonGroup}>
          {buttons
            .map((button: IButton, index: number) => {
              const Icon = button.icon;

              return (
                <Button
                  key={index}
                  variant='text'
                  color='primary'
                  size='large'
                  classes={{ root: classes.buttonRoot, text: classes.buttonLabel }}
                  fullWidth
                  onClick={() => (isLogged ? button.onclick() : button.actionsToRedirect())}>
                  <Icon color='primary' fontSize='large' width='35px' />
                  <Box m='2px' />
                  {button.label}
                </Button>
              );
            })
            .reduce((prev, curr) => (
              <>
                {prev}
                <Divider className={classes.divider} orientation='vertical' variant='middle' />
                {curr}
              </>
            ))}
        </Box>
      </Box>
      <Divider className={classes.dividerComponent} />
      <NewPostProvider>
        <CreatePost props={{ open: postOpen, onClose: () => setPostOpen(false) }} />
      </NewPostProvider>
      {loginOpen && (
        <LoginModal
          modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
          loginModalProps={{ notRedirect: notRedirect }}
        />
      )}
    </Box>
  );
};

export default ActionBar;
