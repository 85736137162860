import { Theme } from '@mui/material';
import { CSSProperties, StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

declare type Classes = 'secondary' | 'ticket' | 'post' | 'document' | 'newsletter' | 'sponsored'

const buildClasses = (): StyleRules<never,Classes> => {
  return { secondary: {}, ticket: {}, post: {}, document: {}, newsletter: {}, sponsored: {} };
};

function buildFilterButtons(theme: Theme) {
  return ['secondary', 'ticket', 'post', 'document', 'newsletter', 'sponsored']
    .map(name => ({ [name]: buildButtons([name], theme.palette[name].main) }))
    .reduce((acc, actual) => ({ ...acc, ...actual }), {});
}

function buildButtons(paletteColor, backgroundColor): CSSProperties {
  return {
    '&:hover, &$selected, &$selected:hover ': {
      border: `1px solid ${backgroundColor}`,
      backgroundColor: backgroundColor,
      color: paletteColor == 'sponsored' ? '#3A3A3A' : '#FFFFFF'
    }
  };
}

export default makeStyles((theme) =>
  createStyles({
    container: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    },

    icon: {
      fontSize: 14,
      [StyleResponsive.onDesktop]: {
        marginRight: '6px',
        marginBottom: '2px'
      },
      [StyleResponsive.onMobile]: {
        marginRight: '5px'
      }
    },

    buttonList: {
      [StyleResponsive.onMobile]: {
        display: 'flex',
        width: '100%',
        overflow: 'auto',
      }
    },

    button: {
      color: '#3A3A3A',
      boxSizing: 'border-box',
      borderRadius: '8px',
      border: '1px solid #828282',
      textTransform: 'none',
      background: '#FFFFFF',
      letterSpacing: '0.25px',
      padding: '5px 14px',

      [StyleResponsive.onDesktop]: {
        marginBottom: '16px'
      },
      [StyleResponsive.onMobile]: {
        minWidth: 'auto',
        width: 'auto',
        marginLeft: '8px',
        justifyContent: 'flex-start'
      }
    },

    text: {
      justifyContent: 'flex-start',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.1px',
      [StyleResponsive.onDesktop]: {
        marginLeft: '4px'
      },
      [StyleResponsive.onMobile]: {
        marginLeft: '8px',
        marginTop: '10px'
      }
    },

    selected: {},

    mainBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline'
    },

    ...buildClasses(),
    ...buildFilterButtons(theme)

  })
);
