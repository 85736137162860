import { CardSkeleton } from '@components/cards/card/Skeleton/CardSkeleton';
import { renderCard } from '@components/cards/CardList/renderer/CardList.renderer';
import {InfiniteScrolling} from '@components/InfiniteScrolling/InfiniteScrolling';
import { Grid } from '@mui/material';
import { UsefulStoreContextProvider } from '@providers/hooks/useful/UsefulStoreContext';
import {ObjectType} from '@service/model';
import { CardResponse } from '@service/objects/model';
import axios from 'axios';
import React from 'react';

interface CardListProps {
  filterType: ObjectType,
  userType?: ObjectType,
  userID?: number
}

const CardList: React.FC<CardListProps> = (object) => {
  const type = object.filterType? object.filterType.toString() : null;
  const publisher = object.userType ? object.userType.toString() : null;

  const render = (card, i) => {
    return (
      <Grid item key={i} xs={12}>
        {renderCard(card)}
      </Grid>
    );
  };

  const url='/api/objects';
  const pageSizeQueryName = 'size';
  const pageSize = 10;
  const pageQueryName = 'page';

  const buildKey = (index: number) => {
    return `${url}?package=${type}&${pageSizeQueryName}=${pageSize}&${pageQueryName}=${index + 1}&user_id=${object.userID}&publisher=${publisher}`;
  }

  const fetcher = (url: string) => axios.get<CardResponse>(url).then((res) => res.data.cards)

  return (
    <div>
      <UsefulStoreContextProvider>
        <InfiniteScrolling
          skeletonNode={<CardSkeleton/>}
          buildKey={buildKey}
          fetcher={fetcher}
          renderItem={render}
          isHome={true}
        />
      </UsefulStoreContextProvider>
    </div>
  );
};

export default CardList;
