import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';

export const CardSkeleton: React.FC = () => {

  return (
    <Card>
      <Skeleton variant="rectangular" animation='pulse' height='33px' width='100%' />
      <CardHeader
        avatar={<Skeleton animation='pulse' style={{borderRadius: '6px'}} variant="rectangular" width={50} height={50} />}
        title={<Skeleton animation='pulse' variant="rectangular" height='16px' width='123px' style={{borderRadius: '20px', marginBottom: '6px'}} />}
        subheader={<Skeleton animation='pulse' variant="rectangular"  height='16px' width='80px' style={{borderRadius: '20px'}} />}
      />
      <CardContent>
        <Skeleton animation='pulse' variant="rectangular"  height='1px' width='100%' style={{marginBottom: '20px', marginTop: '-10px'}} />
          <Skeleton animation='pulse' variant="rectangular" height='16px' width='100%' style={{borderRadius: '20px', marginBottom: '6px'}} />
        <Skeleton animation='pulse' variant="rectangular" height='16px' width='100%' style={{borderRadius: '20px', marginBottom: '6px'}} />
        <Skeleton animation='pulse' variant="rectangular"  height='1px' width='100%' style={{marginBottom: '20px', marginTop: '20px'}} />
        <Box display='flex' justifyContent='space-between'>
          <Skeleton animation='pulse' variant="rectangular" component='div' height='16px' width='20%' style={{borderRadius: '20px', marginBottom: '6px'}}/>
          <Skeleton animation='pulse' variant="rectangular" component='div'  height='16px' width='20%' style={{borderRadius: '20px', marginBottom: '6px'}}/>
        </Box>
      </CardContent>
    </Card>
  );
};
