import ActionBar from '@components/ActionBar/ActionBar';
import CardList from '@components/cards/CardList/CardList';
import styles from '@components/layout/Home/Home.styles';
import MenuFilterCards from '@components/MenuFilterCards/MenuFilterCards';
import { FinishRegister } from '@components/modal/FinishRegister/FinishRegister';
import OnlineEventHome from '@components/OnlineEvent/OnlineEventHome';
import AcoSEO from '@components/SEO/AcoSEO';
import { Box, Grid } from '@mui/material';
import EventCarouselProvider from '@providers/onlineEvent/eventCarouselContext';
import { OnDesktop, OnMobile } from '@providers/Responsive.provider';
import { ObjectType } from '@service/model';
import React, { useState } from 'react';

export interface IHomeProperties {
  filterHome?: ObjectType,
}

const Home: React.FC<IHomeProperties> = (properties) => {
  const classes = styles();

  const location = (typeof window !== 'undefined') && window.location.search;
  const urlParams = new URLSearchParams(location);
  const name = urlParams.get('name');
  const email = urlParams.get('email');

  const [key, setKey] = useState<ObjectType>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(!!name);

  const filterHome = {
    Ticket: 'Consultas',
    Post: 'Publicaciones',
    Document: 'Documentos',
    Newsletter: 'Boletines',
  };

  return (
    <div className={classes.root}>
      <AcoSEO nextSEO={{ title: filterHome[key] }} />
      <Grid container spacing={2}>
        <OnDesktop>
          <Grid item md={3} display='flex' justifyContent='flex-end'>
            <Box position='fixed' maxWidth='15%' marginRight='4px' width='200px'>
              <MenuFilterCards propertiesMenuFilter={{ onSelect: (key) => setKey(key) }}
                               properties={{ filterHome: properties?.filterHome }} />
              <ActionBar smallView={true} />
            </Box>
          </Grid>
        </OnDesktop>
        <Grid item xs={12} md={9}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <ActionBar />
              <OnMobile>
                <EventCarouselProvider>
                  <OnlineEventHome />
                </EventCarouselProvider>
                <MenuFilterCards propertiesMenuFilter={{ onSelect: (key) => setKey(key) }}
                                 properties={{ filterHome: properties?.filterHome }} />
              </OnMobile>
            </Grid>
            <Grid item xs={12}>
              <CardList filterType={properties.filterHome ? properties.filterHome : key} />
            </Grid>
          </Grid>
        </Grid>
        <FinishRegister ModalProps={{ open: modalOpen, onClose: () => setModalOpen(false) }}
                        FinishRegister={{ name: name, email: email }} />
      </Grid>
    </div>
  );
};

export default Home;

